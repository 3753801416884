.auth-header {
    font-size: 1.8rem;
}
.auth-component {
    padding: 3em 10em;
}
input[type='text'], input[type='password'], input[type='email'] {
    border:none;
    background: transparent;
    border-bottom: 1px solid #ddd;
}

.alert-danger {
    .alert-heading {
        color: $alert-danger-color;
        font-weight: bolder;
    }
    p {
        color: $alert-danger-color;
        font-weight: lighter;
    }
    .alert-image {
        width:100%;
    }
}

.min-100-height{
    min-height: 100%;
}