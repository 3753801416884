.mainBackground{
  position: relative;
  width: 100%;
  height: 100vh;
  // background: $primary-background-color;
  .main_discription{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .mainBackground-text{
      text-align: center;
      .mainBackground_maintext{
        font-size: 2em;
        color: $primary-text-color;
      }
      .mainBackground_subtext{
        font-size: 1em;
        color: $secondary-text-color;
      }
    }
  }
}
