.green-text {
    color: #1FCA23 !important;
}

.global_pagination {
    user-select: none;

    li {
        &.disabled {
            a,img {
                cursor: not-allowed;
            }
        }

        &.active {
            @extend .green-text;
            a{
                @extend .green-text;
            }
        }

        img,
        a {
            cursor: pointer;
        }
    }

}