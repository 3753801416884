.filter {
    .select {
        min-width: 250px;
    }
    .label {
        margin-bottom: 7px;
        color: #1C1C1C;
        font-family: Rubik;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 146.667% */
        letter-spacing: -0.2px;
    }
    .host-name {
        color:#3CCA23;
        font-size: 0.9em;
    }
    border-bottom: #1c1c1c1f 2px solid;
}
.filter:focus-within {
    border-bottom: #3CCA23 2px solid;
}
.bookings .label {
    color: #545454;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.2px;
}