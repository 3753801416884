@import "./fonts.scss";

@mixin admin_pages_section_container_wrap {
    width: auto;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid rgba($color: #d4d4d4, $alpha: 0.7);
    padding: 0;
    margin: 1.5em 1em !important;
    font-family: $rubik ;
}

@mixin admin_pages_section_container_section_title {
    font-size: 1em;
    font-weight: 400;
    padding: 1.5em 0em 0em 1.5em;
}

@mixin  nav_tabs_btns_left_border {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

@mixin nav_tabs_btns_right_border {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}