// Vertical harmony is acheived by setting the margins and paddings as multiples of the $base-line-height
@mixin vertical_harmony() {
    $sides: (top, bottom);
    @for $space from 1 through 10 {
      @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
          margin-#{$side}: $base-line-height * $space !important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
          padding-#{$side}: $base-line-height * $space !important;
        }

        .h-#{$space} {
          height: $base-line-height * $space !important;
        }
      }
    }
  }

///Mixin for adding pseudo elements
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

//Example usage for pseudo
// div::after {
//     @include pseudo;
//     top: -1rem; left: -1rem;
//     width: 1rem; height: 1rem;
// }
