.ParkPoolr_menu {
  font-family: $roboto;
  width: 100vw;
  height: 50px;
  position: fixed;
  background: #fff;
  list-style: none;
  z-index: 3;
  // margin-bottom: px;
  -webkit-box-shadow: 10px 10px 74px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 74px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 74px -12px rgba(0, 0, 0, 0.75);

  .menu_contents {
    padding: 10px;

    li {
      float: right;
      padding: 0.5em;
      font-size: 1em;
      font-family: $roboto;
      font-weight: 500;
      text-transform: capitalize;

      img {
        width: 50px;
        float: left;
      }
    }
  }
}

.navbar-right {
  position: absolute;
  right: 8%;
}

.navbar {
  background-color: #fff;

  .nav-link {
    color: #000000;
    font-size: 0.9em;
    font-family: $rubik;
    line-height: 18px;
    font-size: 15px;

    &:hover {
      color: #000000;
      opacity: 0.92;
    }
  }
}

#profile_pic {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.nav-link {
  padding: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.contact {
  background-color: color("backgrounds", 'p-color');
  padding: 0.5em 1em;
  margin-top: 0.5em;
  width: 150px;
  text-align: center;
  border-radius: 22px;
  box-shadow: 1px 4px 14px -3px rgba(0, 0, 0, 0.35);

  &:hover {
    color: #ffffff !important;
  }
}


.border-bottom {

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.right-dropdown{
  left: unset;
  right: 0;
}

.navbar-dropdown-only{

  .dropdown-menu{
    min-width: 7rem;
  }
  .dropdown-toggle::after{
    display: none;
  }
}