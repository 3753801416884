
/// Background colors ///
$primary-background-color: #F7F7F7;
$secondary-background-color: #ffffff;


/// Text Colors ///
$primary-text-color: #1C1C1C;
$secondary-text-color: #545454;
$tertiary-text-color : #969696;
$info-text-color: #007BFF;

$primary-btn-color: #A6A3A3;
$alert-danger-color: #ef5032;




/// Fonts ///
$fonts:(
  p-font:(
    regular: $rubik
  ),
  s-font:(
    regular: $roboto
  ),
);



/// Color Pallet ////
$colors:(
  text:(
    p-color : #1C1C1C,
    s-color: #545454,
    t-color: #969696,
  ),
  backgrounds:(
    p-color :#3CCA23,
    s-color:#007BFF,
    t-color: #9c9c9c
  ),
  input:(
    default: #000,
    active: #333,
    disabled: #eee,
    placeholder: #92929D
  ),
  buttons:(
    primary:#eee,
    hover: darken(#eee,15%)
  )
);




