.signup_form {
  label {
    width: 100%;
  }

  input {
    width: 100%;
  }

  #fname_label,
  #lname_label {
    width: 100%;
  }

  .signup_btn {
    text-align: center;
    padding: 1em 0;
  }

  .small-btn {
    background: $primary-btn-color;
    color: $primary-background-color;
    border-radius: 0.2em;
    padding: 0.5em;
    width: 200px;
    display: inline-block;
    text-transform: uppercase;
  }
}

.verifylinkposition {
  position: absolute;
  right: 0;
  top: 42px;
}

.manuallyAdd{
  position: absolute;
  top: 37px;
  left: 0;
}