// .forget_password_page{
//   text-align: center;
//   .forget_box{
//     position: absolute;
//     left: 50%;
//     transform: translateX(-50%);
//     margin-top: 10em;
//     width: 50%;
//     height: 400px;
//     padding: 4em;
//     // background: $secondary-background-color;
//     -webkit-box-shadow: 2px 13px 62px -32px rgba(0,0,0,0.75);
//     -moz-box-shadow: 2px 13px 62px -32px rgba(0,0,0,0.75);
//     box-shadow: 2px 13px 62px -32px rgba(0,0,0,0.75);
//     .forget_box-text{
//       text-align: center;
//       .forget_primary-text{
//         font-weight: 600;
//         font-size: 1.5em;
//       }

//     }
//     .forget_form{
//       width: 50%;
//       position: relative;
//       left: 50%;
//       transform: translateX(-50%);
//       label{
//         float: left;
//       }
//       input{
//         width: 100%;
//         padding: 0.5em;
//         color: $secondary-text-color;
//       }
//       button{
//         margin-top: 2em;
//         padding: 0.5em 3em;
//         background: $primary-btn-color;
//         color: #ffffff;
//         text-transform: uppercase;
//       }
//       a{
//         color: $primary-text-color;
//         text-decoration: underline!important;
//         text-transform: capitalize;
//       }
//     }
//   }
// }

.auth-header{
  font-size: 1.5em;
}

.text-muted{
  font-size: 0.8em;
}

.email_form{
  margin-top: 2em;

  .lable_email{
    font-size: 0.9em;
  }

}
.custom_btn{
  background-color: #3CCA23 ;
  border: 2px solid #3CCA23 ;
}