////////////////////////////////////////////
// Roboto bold:700,regular:400,light:300
////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');
$roboto: 'Roboto', sans-serif;
$rubik: 'Rubik', ;

// $small: 0.875rem;
// $medium: 1rem;
// $large: 2rem;
