.social-fb {
  background-color: #3b5999;
  color: white;

  &:hover {
    color: white;
  }
}

.social-google {
  background-color: white;
  color: black;

  &:hover {
    color: black;
  }
}

.social-buttons {
  width: 100% !important;
  border-radius: 0.6em;
  letter-spacing: -0.13px;
  font-weight: 400;
  font-size: 1rem;
  box-shadow: 1px 2px 8px -4px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: 1px 4px 14px -3px rgba(0, 0, 0, 0.35);
  }

  &:active {
    outline: none !important;
  }
}

.social-icons {
  max-width: 35px;
  max-height: 35px;
  margin-right: 6px;

  &.social-google {
    margin-right: 10px;
  }
}

.social-text {
  position: relative;
  top: 2px;
}

.social-buttons-row {
  .col-md-6 {
    padding: 0 !important;

    .container {
      .social-buttons {
        padding: 1.1rem 0.8rem;
      }
    }
  }
}


%pseudo-line {
  content: "";
  position: absolute;
  height: 1px;
  border-top: 1px solid #e4e4e4;
  top: 10px;
  width: 75px;
}

.divider {
  margin-top: 25px;
  margin-bottom: 20px;
  width: 100%;
  display: inline-block;
  position: relative;

  &:before {
    @extend %pseudo-line;
    right: 50%;
    margin-right: 25px;
  }

  &:after {
    @extend %pseudo-line;
    left: 50%;
    margin-left: 25px;
  }
}