/* Do not edit
Developed and maintained by Fountane LLC */

///Note: Vertical harmony as been acheived using line-heights

$base-font-size: 0.9rem;
$base-line-height: 1.25 * $base-font-size;

$type-settings: (
  xxl: (
    font-size: $base-font-size + 2rem,
    line-height: $base-line-height*4
  ),
  xl: (
    font-size: $base-font-size + 0.8rem,
    line-height: $base-line-height*2
  ),
  l: (
    font-size: $base-font-size + 0.4rem,
    line-height: $base-line-height*2
  ),
  m: (
    font-size: $base-font-size,
    line-height: $base-line-height
  ),
  s: (
    font-size: $base-font-size - 0.2rem,
    line-height: $base-line-height
  ),
  xs: (
    font-size:  $base-font-size - 0.4rem,
    line-height: $base-line-height
  )
);


/// Helper Functions and Mixins ///
@function type-scale($size) {
  @return map-get(
    map-get($type-settings, $size), 'font-size');
}

@function line-height($size) {
  @return map-get(
    map-get($type-settings, $size), 'line-height');
}

@function get-font($type, $variation) {
  @if($variation != null) {
    @return map-get(map-get($fonts, $type), $variation),
  }
  @else {
    @return map-get($fonts, $type),
  }
}

@mixin font($size,$family:'p-font',$weight:'regular',$color:'s-color') {
  font-size: type-scale($size) !important;
  line-height: line-height($size);
  font-family: get-font($family,$weight);
  color: color(text,$color);
  //Responsive Font Sizes
  @if($size==xxl){
    @include device(tablet){
      font-size: type-scale($size) + 1rem;
    }
  }
}
