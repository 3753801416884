//configuration styles
@import "config/scss/breakpoints";
@import "config/scss/fonts";
@import "config/scss/typography";
@import "config/scss/structures";
@import "config/scss/crossbrowser";
@import "config/scss/colors";
@import "config/scss/variables";

// component styles
@import "components/shared/navbar/style";
@import "components/shared/mainBackground/style";
@import "components/auth/login/style";
@import "components/auth/signup/style";
@import "components/auth/forgetPass/style";
@import "components/auth/tempPass/style";

@import "components/auth/style";
@import "components/auth/socialButtons/style";
@import "components/shared/Modal//style.scss";

@import "config/scss/mixins";


@include vertical_harmony();


html,
body {
  height: 100%;
}

body {
  background: $primary-background-color;
  font-family: $rubik;
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  color: color("text", "s-color");

  a {
    outline: 0 !important;
    text-decoration: none !important;
    color: color("backgrounds", "s-color");
  }

  * {
    box-sizing: border-box;
  }
}

.semi-bold {
  font-weight: 500;
}

.full-bold {
  font-weight: 700;
}

.logo-img {
  margin-left: 12.5px;
}


.footer_custom {
  padding-top: 5em !important;
  padding-bottom: 4em !important;
}

.pointer {
  cursor: pointer;
}

.primary-text-color {
  color: $primary-text-color !important;
}

.secondary-text-color {
  color: $secondary-text-color !important;
}

.tertiary-text-color {
  color: $tertiary-text-color !important;
}

.info-text-color{
  color: $info-text-color !important;
}

.font-xl {
  @include font(xl, "p-font", "bold", "p-color");
}

.font-l {
  @include font(l, "p-font", "regular", "p-color");
}

.font-m {
  @include font(m);
}

.font-s {
  @include font(s, "p-font", "regular", "s-color");
}

.font-xs {
  @include font(xs, "p-font", "regular", "s-color");
}

.global_link-text {
  @extend .font-m;
  color: color("backgrounds", "s-color") !important;
}

.recharts-wrapper {
  margin-left: auto;
}

.yAxis-text {
  position: absolute;
  transform: rotate(-90deg);
  top: 50%;
  left: -25px;
  margin-bottom: 0;
  @extend .primary-text-color;
}

.xAxis-text {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -30px;
  margin-bottom: 0;
  @extend .primary-text-color;
}



.disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.global_input {
  outline: none !important;
  box-shadow: none !important;
  background-color: #f7f7f7;
  @extend .font-m;

  &:disabled {
    @extend .disabled;
  }

  &.bg-white {
    background-color: #fff;
  }

  &:focus {
    @extend .font-m;
    background-color: #f7f7f7;
  }

  @include input-placeholder() {
    opacity: 0.4;
  }
}

.global_btn {
  background-color: color("backgrounds", "p-color");
  color: #fff;
  opacity: 0.9;
  @include border-radius(5px);
  @extend .semi-bold;

  &.no-border-radius {
    border-radius: 0;
  }

  &.modal_width {
    width: 176px;
  }

  &.add_min_width {
    // min-width: 170px;
    min-width: 120px;
  }

  &.add_min_height {
    min-height: 45px;
  }

  &.add_max_width {
    max-width: 500px;
  }

  &:hover {
    color: #fff;
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.global_card {
  @include border-radius(8px);
  @include box-shadow(0 1px 6px 0 rgba(0, 0, 0, 0.1));
}

.global_container_card {
  @include border-radius(4px);
  @include box-shadow(unquote("#{0 0 0 1px rgba(63,63,68,0.05)}, #{ 0 1px 3px 0 rgba(63,63,68,0.15)}"));
  background-color: #fff;

  &.add_min_width {
    min-width: 1000px;
  }

  &.section_gap {
    padding: 1.5em 0em 0em 1.5em;
  }
}

.global_small_green_btn {
  border-style: solid;
  border-color: #3cca23;
  border-radius: 5px;
  background: transparent;
  padding: 0.3em 1.5em;
  color: #3cca23;
  outline: none !important;
  line-height: 17px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.pos_rel {
  position: relative !important;
}



/// global Accordian ///@include

.global_accordian {
  height: 40px;
  border-radius: 6px 6px 0 0;
  background-color: #e4e4e4;
  text-align: left;
  @extend .semi-bold;
  @extend .primary-text-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  // margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.rotate_angle {
  font-size: 1.2rem;
  margin-right: 7px;
}

.global_table {

  .table {
    color: $secondary-text-color !important;
    margin-bottom: 0;

    th {
      padding: 1.5rem;
      border: 0;
      border-bottom: 1px solid #dee2e6;

      p {
        margin-bottom: 0;
      }

      @extend .font-s;
      @extend .semi-bold;
      @extend .primary-text-color;
    }

    td {
      padding: 1.5rem;
      @extend .font-m;
      line-height: 1.5;
      vertical-align: middle;
      max-width: unset;

      p {
        margin-bottom: 0;
        @extend .font-m;
      }
    }


    tr:hover {
      color: #000;
      background-color: rgba(0, 0, 0, .02);

      td {
        @extend .primary-text-color;

        p {
          @extend .primary-text-color;
        }
      }
    }
  }


  &.add-maxWidth {
    table {
      td {
        max-width: 172px;
      }
    }
  }
}

.first_child {
  color: #000000;
  text-decoration: underline;
}

.modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  // height: 200px;
  width: 708px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  margin: 0;

  .modal-content {
    border: 0;
    width: 708px;

    .modal-header {
      border: 0;

      .close {
        padding: 0;
        margin: 0;

        &:focus {
          outline: 0;
        }
      }
    }

    .modal-footer {
      border-top: 0;
      margin-top: 8px;
    }
  }

}



// this code for nav pills
.section_wrap {

  .nav {
    border-bottom: none;
  }

  .nav_btn {
    height: 35px;
    min-width: 100px;
    margin: 0;
    font-size: 0.7em;
    border-radius: 0;
    color: #000000;
    padding: 11px 15px;
  }

  ul {
    li {
      button {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  .nav_btn_left {
    @include nav_tabs_btns_left_border
  }

  .nav_btn_right {
    @include nav_tabs_btns_right_border
  }

  .btn-outline-success {
    background-color: #D3FFD4;
    font-weight: 500;
    padding: 11px 15px;
  }

  .btn-outline-secondary {
    border: 1px solid rgba($color: #d4d4d4, $alpha: 0.7);
    line-height: 1;
    padding: 11px 15px;

  }

}

.badge-light {
  color: #212529;
  background-color: transparent;
}

.inivisble-input {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
}

.only-profile-texts {
  color: #000 !important;

  input {
    color: #000 !important;
    text-transform: capitalize;
  }

  &.dull {
    opacity: 0.8;
  }
}
.block_loading {
  width: 100%;
  height: 100%;
  display: flex;
  place-content: center;
  place-items: center;
  padding: 6em;
  .spinner {
    margin-bottom: 1em;
    width: 5em;
    transform: scale(1.2);
  }
}
.spinner {
  margin-bottom: 1em;
  width: 5em
}

.no-content {
  padding: 6em;
}

.route {
    color: grey;
  cursor: pointer !important;
  text-decoration: underline !important;
  &:hover {
    color: #007BFF;
  }
}
.global_loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  background-color: transparent;

  .center-box {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%) scale(1);
    transition: transform 0.3s;
    background-color: #000;
    border-radius: 4px;
    width: 200px;

    p {
      color: #fff !important;
    }

    &.show {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.fullPageLoader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  place-content: center;
  place-items: center;
  opacity: 0.7;
}
.without-footer {
  min-height: calc(100vh);
}

.timers {
  position: relative;
  z-index: 2;
}

.newdatepicker {
  width: 100%;

  .react-date-picker__wrapper{
    border: 0;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .react-datetime-picker__wrapper {
    border: 0;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }


}



.search-code {
  // margin-bottom: 20px;

  input {
    max-width: 300px;
    margin-left: auto;
    border-bottom: #1c1c1c1f 2px solid;
    padding-right: 2.5rem;

    &.lg-width {
      max-width: 375px;
    }

    &::placeholder {
      color: #555;
    }
  }

  .clear-text {
    position: absolute;
    right: 0;
    bottom: 9px;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-all;
}



.recharts-cartesian-axis-ticks,
.recharts-cartesian-axis-tick {
  font-size: 13px;
}

// Override the dot on the month view 
.toastui-calendar-weekday-event-dot{
  top:0px !important;
  left:0px!important;
  right:0px!important;
  bottom:0px!important;
  height:100% !important;
  width:100% !important;
  border-radius: 0px !important;
}

.toastui-calendar-weekday-event-title{
  position:absolute;
  top:0px;
  left:8px;
  right:8px;
  bottom:0px;
  height:"100%";
  width:"100%";
}


.reload-button {
  background: transparent;
  border: none;
  &:hover {
    background: transparent;
  }
}



// Bootstrap Loading Skeleton
%loading-skeleton {
  color: transparent !important;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: .4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1, h2, h3, h4, h5, h6,
  p, li,
  .btn,
  label,
  .form-control {
   &:not(.loading-skeleton-ignore) {
    @extend %loading-skeleton;
    }
  }

}

.BarChartLoader {
  display: flex;
  place-content: center;
  place-items: center;
  transform: translateY(-100%);
  position: absolute;
}

.user-img {
  width: 40px !important;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  background-color:rgba(0, 0, 0, 0.1);
  background-image: url("./Assets/imgs/defaultUser.svg");
  &.no-bg {
    background-color: transparent !important
  }
  &.small {
    width: 20px !important;
    height: 20px !important;
  }
  &.unround {
    border-radius: 0;
  }
}
.pe-none {
  pointer-events: none;
  cursor: not-allowed;
}
.fade {
  -webkit-transition-duration: 0.1s !important;
  transition-duration: 0.1s !important;
}