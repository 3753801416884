

.email-input {
  margin-bottom: 2px;
}

.login_form {
  padding: 1em 10em;

  .email,
  .password {
    padding: 0.5em 0;
  }

  input[type='text'],
  input[type='password'] {
    width: 100%;
    padding: 0.5em;
    color: $secondary-text-color;
  }

  .forget_password {
    float: right;
    text-decoration: underline !important;
    color: $primary-text-color;
  }

  .signup_text {
    position: absolute;
    padding: 1em;
    left: 50%;
    transform: translateX(-50%);

    a {
      color: $primary-text-color;
      text-decoration: underline !important;
    }
  }
}




.all-seeing-eye {
  float: right;
  position: relative;
  z-index: 2;
  top: -30px;
  right: 12px;
}

label,
.footer-text,
.frgt-pass,
.login-btn {
  font-size: 0.9rem;
}

.remember-me {
  margin-left: 4px;
}